/* SignUpFormProvider.module.scss */

.form {
  width: 100%;
}

.formFields {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formGroup {
  margin-bottom: 1.25rem;
  width: 100%;
  
  &:last-of-type {
    margin-bottom: 1.5rem;
  }
  
  label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #111827;
    margin-bottom: 0.25rem;
  }
}

.passwordRequirements {
  margin-top: 1.5rem;
  font-size: 0.75rem;

  ul {
    margin-left: 1rem;
    list-style-type: disc;
  }
}

.submitButtonContainer {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  width: 100%;
  
  button {
    width: 100%;
    background-color: #552BD8 !important; // Exception to design system
    color: white;
    font-weight: 500;
    padding: 0.75rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    
    &:hover {
      background-color: #4f46e5;
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.4);
    }
  }
}

.signupFooter {
  text-align: center;
  font-size: 0.75rem;
  color: #6b7280;
}

.legalText {
  text-align: center;
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 1rem;
  
  p {
    margin-bottom: 0.5rem;
  }
}

.legalLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  
  a {
    color: #6b7280;
    text-decoration: none;
    font-size: 0.75rem;
    
    &:hover {
      text-decoration: underline;
      color: #4f46e5;
    }
  }
}

@media (max-width: 768px) {
  .formGroup {
    margin-bottom: 1rem;
  }
  
  .passwordRequirements {
    font-size: 0.7rem;
  }
  
  .submitButtonContainer {
    margin-top: 0.75rem;
    margin-bottom: 1.25rem;
  }
}
