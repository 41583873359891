$sign-up-breakpoint: 768px;
$color-brand-accent: #552BD8;

.signupContainer {
  color: #000000;
  display: flex;
  min-height: 100vh;
  background-color: #FFF;
  
  @media (max-width: $sign-up-breakpoint) {
    flex-direction: column-reverse;
  }
}

.leftSection {
  background: 
    linear-gradient(135deg, #F4F4F2 65%, #8f76f447),
    linear-gradient(#F4F4F2 85%, #cff9ffe1);
  align-items: center;
  display: flex;
  width: 50%;
  justify-content: center;
  padding: 2.5rem;

  @media (max-width: $sign-up-breakpoint) {
    width: 100%;

    .logoContainer {
      display: none;
    }

  }
}

.leftSectionContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 32rem;
}

.rightSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: white;
  padding: 2.5rem;

  .logoContainer {
    display: none;
  }
  
  @media (max-width: $sign-up-breakpoint) {
    width: auto;

    .logoContainer {
      display: block;
      margin-bottom: 1.5rem;
    }  
  }
}

.logoContainer {
  @media (max-width: $sign-up-breakpoint) {
    text-align: center;
  }
}

.logo {
  height: 2rem;
}

.headingText {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
}

.providerCount {
  .highlight {
    color: $color-brand-accent;
    font-weight: 600;
  }
}

.benefitsList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.benefitItem {
  display: flex;
  align-items: flex-start;
  
  svg {
    margin-right: 0.75rem;
    min-width: 20px;
  }
}

.testimonial {
  background-color: #FFFFFF;
  padding: 1.25rem;
  border-radius: 0.5rem;
  min-height: 16rem; // Set a fixed minimum height that fits all testimonials
  display: flex;
  flex-direction: column;
  
  .testimonialText {
    font-size: 1rem;
    margin-bottom: 0.75rem;
    line-height: 1.5;
    flex-grow: 1; // Takes available space
  }
  
  .testimonialAuthor {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .stars {
    display: flex;
    align-items: center;
    margin-top: auto; // Push to bottom
    
    svg {
      margin-right: 0.25rem;
    }
    
    .navigationButtons {
      margin-left: auto;
      
      button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0.25rem;
        
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.formContainer {
  width: 100%;
  max-width: 32rem;
}

.formTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  text-align: center;
}

.loginText {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.75rem;
  
  a {
    color: #6366f1;
    font-weight: 600;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: $sign-up-breakpoint) {
    margin-bottom: 1.5rem;
  }
}

.certifications {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  
  @media (max-width: $sign-up-breakpoint) {
    flex-wrap: wrap;
  }
}

.certLogo {
  margin: 0 0.75rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  img, svg {
    height: 2rem;
  }
  
  @media (max-width: $sign-up-breakpoint) {
    margin: 0.5rem;
  }
}

.hipaaLogo {
  composes: certLogo;
 // background-image: url('/path-to-hipaa-logo.png');
}

.hitrustLogo {
  composes: certLogo;
 // background-image: url('/path-to-hitrust-logo.png');
}

.cncLogo {
  composes: certLogo;
 // background-image: url('/path-to-cnc-logo.png');
}

.legalText {
  font-size: 0.75rem;
  text-align: center;
  margin-top: 1.25rem;

  label {
    display: block;
    font-size: 0.75rem;
  }
  
  a {
    color: #727272;
    font-size: 0.75rem;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: $sign-up-breakpoint) {
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }
}